import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { StylesProvider } from '@material-ui/styles';

const root = ReactDOM.createRoot(document.getElementsByTagName('mam-widget-admin')[0]);
root.render(
  <React.StrictMode>
    <StylesProvider>
      <App />
    </StylesProvider>
  </React.StrictMode>
);