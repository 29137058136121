import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

export default function WidgetEditor_TodaysRatesTable_Config(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box className="Input">
              <FormControl fullWidth>
                <InputLabel className="selectLabelCorrection">Rate Precision</InputLabel>
                <Select
                  value={props.widget.ratePrecision}
                  label="Rate Precision"
                  onChange={(event) => props.onChange("ratePrecision", event.target.value)}
                  variant="outlined"
                >
                  <MenuItem value={0}>No decimal places</MenuItem>
                  <MenuItem value={1}>1 decimal place</MenuItem>
                  <MenuItem value={2}>2 decimal places</MenuItem>
                  <MenuItem value={3}>3 decimal places</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="Input">
              <FormControl fullWidth>
                <InputLabel className="selectLabelCorrection">APR Precision</InputLabel>
                <Select
                  label="APR Precision"
                  value={props.widget.aprPrecision}
                  onChange={(event) => props.onChange("aprPrecision", event.target.value)}
                  variant="outlined"
                >
                  <MenuItem value={0}>No decimal places</MenuItem>
                  <MenuItem value={1}>1 decimal place</MenuItem>
                  <MenuItem value={2}>2 decimal places</MenuItem>
                  <MenuItem value={3}>3 decimal places</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="Input">
              <TextField
                  label="Memo"
                  multiline
                  maxRows={3}
                  style={{width: "100%"}}
                  value={props.widget.memo}
                  onChange={(event) => props.onChange("memo", event.target.value)}
                  variant="outlined"
                />
            </Box>
            <Box className="Input">
              <FormControl fullWidth>
                <InputLabel className="selectLabelCorrection">Payment Display</InputLabel>
                <Select
                  label="Payment Display"
                  value={props.widget.paymentDisplay}
                  onChange={(event) => props.onChange("paymentDisplay", event.target.value)}
                  variant="outlined"
                >
                  <MenuItem value={'perLoanAmount'}>P&I for loan amount</MenuItem>
                  <MenuItem value={'per1000'}>P&I per $1000</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
    </React.Fragment>
  );
}
