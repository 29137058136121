import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import plugImage from './../Assets/material-icon-plus.png';
import Api from './Api';

export default class CreditUnions extends React.Component {

    state = {
        creditUnions: []
    }

    constructor(props) {
        super(props);

        this.api = new Api();
        this.logout = props.logout;
        
    }

    componentDidMount() {
        this.api.getCreditUnions(results => {
            this.setState({ creditUnions: results});
        });
    }

    render() {
        return (

            <div className="CreditUnions">
                <hr />
                <Grid container spacing={2}>
                { this.state.creditUnions.map((creditUnion) => {
                    return (
                        <Grid item md={4} key={creditUnion.siteId}>
                        <div>
                            <Card className="creditUnionCard" onClick={() => this.props.handleSelectCu(creditUnion.siteId)}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={creditUnion.logo}
                                alt={creditUnion.name}
                            />
                            <CardContent>
                            <Typography variant="body1" component="div" align="left">
                                <strong>{creditUnion.name}</strong>
                            </Typography>
                            <Typography component="div" align="left" style={{color:"#d3d3d3", fontSize:"10px"}}>
                                {creditUnion.siteId}
                            </Typography>
                            </CardContent>
                            </Card>
                        </div>
                        </Grid>
                    )
                })}
                    <Grid item md={4}>
                        <div>
                            <Card className="creditUnionCard" onClick={() => this.props.handleSelectCu('NEW')}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={plugImage}
                                />
                                <CardContent>
                                    <Typography variant="body1" component="div">
                                        <strong>Add Credit Union</strong>
                                    </Typography>
                                </CardContent>
                                <Typography variant="caption" component="div" align="left" style={{color:"#d3d3d3"}}>
                                    &nbsp;
                                </Typography>
                            </Card>
                        </div>
                    </Grid>
                </Grid>
            </div>

       );
    }
}