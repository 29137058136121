import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid';
import { Delete, WidgetsSharp } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

export default function CreditUnionWidgetsTable(props) {
  
  var widgets = props.widgets;

  return (
    <React.Fragment>
      { widgets != null && 
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell colSpan={4} style={{background:"#d6e2fb"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" component="div" align="center">
                            Widgets
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>
                    </Grid>
                  </TableCell>

              </TableRow>
              <TableRow style={{background:"rgb(235 235 235)"}}>
                <TableCell align="left"><strong>Type</strong></TableCell>
                <TableCell align="left"><strong>ID</strong></TableCell>
                <TableCell align="left"><strong>Memo</strong></TableCell>
                <TableCell align="left">&nbsp;</TableCell>
              </TableRow>
                { widgets.map(widget => (
                  <TableRow key={widget.id}>
                    <TableCell align="left">{widget.type}</TableCell>
                    <TableCell align="left">{widget.id}</TableCell>
                    <TableCell align="left">{widget.memo}</TableCell>
                    <TableCell align="left">
                        <Link>
                            <EditIcon onClick={() => props.editWidget(widget)}></EditIcon>
                        </Link>
                        <Link>
                            <DeleteIcon style={{color:'maroon'}} onClick={() => props.deleteWidget(widget)}></DeleteIcon>
                        </Link>
                    </TableCell>
                  </TableRow>
                ))}
                { !widgets.length && 
                  <TableRow>
                    <TableCell align="left" colSpan={4}>No widgets configured for this site</TableCell>
                  </TableRow>
                }
                { props.showAddWidget &&
                  <TableRow>
                    <TableCell align="left" style={{padding: "15px", width: "300px"}}>
                      <FormControl fullWidth>
                        <InputLabel className="selectLabelCorrection">Select Widget To Add</InputLabel>
                          <Select
                            label="Select Widget To Add"
                            className="select-condensed"
                            onChange={(event) => props.selectWidgetToAdd(event.target.value)}
                            variant="outlined"
                          >
                            <MenuItem value={"TodaysRatesCards"}>Todays Rates Cards</MenuItem>
                            <MenuItem value={"TodaysRatesTable"}>Todays Rates Table</MenuItem>
                            <MenuItem value={"PaymentCalculator"}>Payment Calculator</MenuItem>
                            <MenuItem value={"RentVsBuyCalculator"}>Rent vs Buy Calculator</MenuItem>
                            <MenuItem value={"HowMuchDoINeedCalculator"}>How Much Do I Need Calculator</MenuItem>
                            <MenuItem value={"HowMuchCanIAffordCalculator"}>How Much Can I Afford Calculator</MenuItem>
                            <MenuItem value={"MicroWidget"}>Micro Widget</MenuItem>
                          </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell align="left">
                      <Button variant="contained" color="primary" onClick={() => props.addWidget()}>Add</Button>
                      <Button onClick={() => props.closeAddWidget()}>Cancel</Button>
                    </TableCell>
                  </TableRow>
                }
                { !props.showAddWidget &&
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      <Link onClick={() => props.openAddWidget()}>
                        <EditIcon></EditIcon> Add Widget
                      </Link>
                    </TableCell>
                  </TableRow>
                }
            </TableBody>
          </Table>
        </TableContainer>
      }
    </React.Fragment>
  );
}
