import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

export default function WidgetEditor_RentVsBuyCalculator_Config(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <h4>Global Inputs</h4>

          <Box className="Input">
            <FormControl fullWidth>
              <InputLabel className="selectLabelCorrection">Product</InputLabel>
              <Select
                label="Product"
                value={props.widget.product}
                onChange={(event) => props.onChange("product", event.target.value)}
                variant="outlined"
              >
                {props.products.map(p => (
                  <MenuItem value={p}>{p}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="Input">
            <FormControl fullWidth>
              <InputLabel className="selectLabelCorrection">Product Type</InputLabel>
              <Select
                value={props.widget.productType}
                label="Product Type"
                onChange={(event) => props.onChange("productType", event.target.value)}
                variant="outlined"
              >
                <MenuItem value="conventional">Conventional</MenuItem>
                <MenuItem value="fha">FHA</MenuItem>
                <MenuItem value="va">VA</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className="Input">
            <TextField
              label="Loan Term In Months"
              fullWidth
              type="number"
              placeholder="e.g. 360"
              value={props.widget.loanTerm}
              onChange={(event) => props.onChange("loanTerm", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>


          <Box className="Input">
            <TextField
              label="Default Purchase Price"
              type="number"
              fullWidth
              title="The default purchase price amount shown in the widget"
              placeholder="e.g. 250000"
              value={props.widget.purchasePrice}
              onChange={(event) => props.onChange("purchasePrice", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>
          <Box className="Input">
            <TextField
              label="Down Payment Amount"
              type="number"
              fullWidth
              title="The default down payment amount shown in the widget"
              placeholder="e.g. 50000"
              value={props.widget.downPaymentAmount}
              onChange={(event) => props.onChange("downPaymentAmount", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Tax Bracket"
              fullWidth
              type="number"
              title="Tax Bracket"
              placeholder="e.g. .15"
              value={props.widget.taxBracket}
              onChange={(event) => props.onChange("taxBracket", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Inflation Rate"
              fullWidth
              type="number"
              title="Rate of Inflation (used to annually compute Renters Insurance, Property Insurance, and Maintenance Costs)"
              placeholder="e.g. .035"
              value={props.widget.inflationRate}
              onChange={(event) => props.onChange("inflationRate", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

        </Grid>
        <Grid item xs={4}>
          <h4>Purchase Assumptions</h4>

          
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Annual Appreciation Rate"
                type="number"
                title="Average increase in Property value"
                placeholder="e.g. .02"
                value={props.widget.annualAppreciationRate}
                onChange={(event) => props.onChange("annualAppreciationRate", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>

          <Box className="Input">
            <TextField
              label="Annual Homeowners Insurance"
              type="number"
              fullWidth
              title="Percentage of purchase amount for homeowners insurance (annual premium, subject to inflation)"
              placeholder="e.g. .0025"
              value={props.widget.annualHomeownersInsurancePremium}
              onChange={(event) => props.onChange("annualHomeownersInsurancePremium", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Annual Property Taxes"
              type="number"
              fullWidth
              title="Percentage of purchase amount for property taxes (annual taxes)"
              placeholder="e.g. .0175"
              value={props.widget.annualPropertyTaxes}
              onChange={(event) => props.onChange("annualPropertyTaxes", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Realtor Fees on future sale"
                type="number"
                title="Percentage of property value (purchase price + appreciation) allocated for realtor fees"
                placeholder="e.g. .06"
                value={props.widget.realtorFeesOnFutureSale}
                onChange={(event) => props.onChange("realtorFeesOnFutureSale", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>

          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Annual Maintenance Costs"
                type="number"
                title="Percentage of purchase amount for annual maintenance cost (subject to inflation)"
                placeholder="e.g. .0025"
                value={props.widget.annualMaintenanceCost}
                onChange={(event) => props.onChange("annualMaintenanceCost", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>

          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Closing Costs Fixed Amount / Floor"
                type="number"
                title="'f' in closing costs calculation: cc = f + v * (pp - t) [f=fixed, v=variable, pp=purchase price, t=threshold]"
                placeholder="e.g. 3000"
                value={props.widget.closingCostsFixed}
                onChange={(event) => props.onChange("closingCostsFixed", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>

          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Closing Costs Variable Percentage"
                type="number"
                title="'v' in closing costs calculation: cc = f + v * (pp - t) [f=fixed, v=variable, pp=purchase price, t=threshold]"
                placeholder="e.g. .00125"
                value={props.widget.closingCostsVariablePercentage}
                onChange={(event) => props.onChange("closingCostsVariablePercentage", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>

          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Closing Costs Variable Threshold"
                type="number"
                title="'t' in closing costs calculation: cc = f + v * (pp - t) [f=fixed, v=variable, pp=purchase price, t=threshold]"
                placeholder="e.g. 150000"
                value={props.widget.closingCostsVariableThreshold}
                onChange={(event) => props.onChange("closingCostsVariableThreshold", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
        </Grid>
        <Grid item xs={4} align="left">
          <h4>Rent Assumptions / Other</h4>
          <Box className="Input">
            <TextField
              label="Default Rent Payment"
              type="number"
              fullWidth
              title="Default rent payment shown in the calculator"
              value={props.widget.monthlyRentPayment}
              placeholder="e.g. 1800"
              onChange={(event) => props.onChange("monthlyRentPayment", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Monthly Rent Insurance"
              type="number"
              title="Monthly Rent Insurance (subject to annual inflation)"
              fullWidth
              value={props.widget.monthlyRentInsurance}
              placeholder="e.g. 100"
              onChange={(event) => props.onChange("monthlyRentInsurance", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Annual Rent Increase"
              type="number"
              fullWidth
              title="Expected percentage increase of rent annually"
              value={props.widget.annualRentIncrease}
              placeholder="e.g. .02"
              onChange={(event) => props.onChange("annualRentIncrease", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Pre-tax return on savings"
              type="number"
              fullWidth
              title="Annual ROI of down payment amount"
              value={props.widget.preTaxReturnOnSavings}
              placeholder="e.g. .04"
              onChange={(event) => props.onChange("preTaxReturnOnSavings", event.target.value, 'number')}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Lead Capture Button Text"
              fullWidth
              placeholder="e.g. Get Quote"
              value={props.widget.leadCaptureText}
              onChange={(event) => props.onChange("leadCaptureText", event.target.value)}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Memo"
              multiline
              maxRows={3}
              fullWidth
              value={props.widget.memo}
              onChange={(event) => props.onChange("memo", event.target.value)}
              variant="outlined"
            />
          </Box>

          <Box className="Input">
            <TextField
              label="Apply Now Link Url"
              multiline
              maxRows={3}
              fullWidth
              value={props.widget.applyNowLinkUrl}
              onChange={(event) => props.onChange("applyNowLinkUrl", event.target.value)}
              variant="outlined"
            />
          </Box>

          
         
        </Grid>

      </Grid>
    </React.Fragment>
  );
}
