import * as React from 'react';
import axios from 'axios';

export default class Api {

    gatewayApiRoot = "unset";

    init = () => {
        this.gatewayApiRoot = process.env.REACT_APP_MAM_GATEWAY_API_URL;
    }

    getMamConfig = (apiVersion, callback) => {
        var url = `${process.env.REACT_APP_MAM_GATEWAY_API_URL}mam/config/${apiVersion}`;
        axios.get(url).then(result => {
            callback(result.data);
        });
    }

    saveMamConfig = (config, callback) => {
        var url = `${process.env.REACT_APP_MAM_GATEWAY_API_URL}mam/config`;
        axios.post(url, config).then(result => {
            callback(result.data);
        });
    }

    getCreditUnions = (callback) => {
        
        this.init();
        
        var url = `${this.gatewayApiRoot}sites`;

        axios.get(url).then(result => {
            var sites = result.data;
            callback(sites);
        });
    }

    getCreditUnion = (siteId, callback) => {
        
        this.init();
        var url = `${this.gatewayApiRoot}site/${siteId}/config`;

        axios.get(url).then(result => {
            var config = result.data;
            callback(config);
        });
    }

    saveConfig = (config, callback) => {
        
        this.init();
        var url = `${this.gatewayApiRoot}site/config`;

        axios.put(url, config).then(result => {
            callback(result.data);
        });
    }

    deleteConfig = (siteId, callback) => {
        this.init();
        var url = `${this.gatewayApiRoot}site/${siteId}/config/delete`;

        axios.post(url).then(result => {
            callback(result.data);
        });
    }

    getProducts = (siteId, apiVersion, callback) => {
        
        if (apiVersion == 99) { // UAT
            var url = `${process.env.REACT_APP_MAM_API_UAT_URL}rates/current/json/${siteId}`;
            axios.get(url).then(result => {
                var rates = result.data.rates;
                var results = rates.map(r => { return r.product});
                callback(results);
            }).catch(onError);
        } else if (apiVersion == 4) {
            var url = `${process.env.REACT_APP_MAM_API_V4_URL}rates/current/json/${siteId}`;
            axios.get(url).then(result => {
                var rates = result.data.rates;
                var results = rates.map(r => { return r.product});
                callback(results);
            }).catch(onError);
        } else {
            
            callback([]);
        }

        function onError(err) {
            alert("Unable to retrieve products from API version " + apiVersion);
        }
    }

    authenticate = (token, callback) => {
        axios.post(`${process.env.REACT_APP_MAM_GATEWAY_API_URL}authenticate`, { token } ).then(result => {
            callback(result);
        })
    }

    login = (passcode, callback) => {
        axios.post(`${process.env.REACT_APP_MAM_GATEWAY_API_URL}login`, { passcode } ).then(result => {
            callback(result);
        })
    }


}