import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

export default function WidgetEditor_HowMuchDoINeedCalculator_Config(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Default Purchase Price"
                type="number"
                placeholder="e.g. 250000"
                value={props.widget.purchasePrice}
                onChange={(event) => props.onChange("purchasePrice", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Default Down Payment"
                type="number"
                placeholder="e.g. 50000"
                value={props.widget.downPayment}
                onChange={(event) => props.onChange("downPayment", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Monthly Association Dues"
                type="number"
                placeholder="e.g. 100"
                value={props.widget.monthlyAssociationDues}
                onChange={(event) => props.onChange("monthlyAssociationDues", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Monthly Car Payments"
                type="number"
                placeholder="e.g. 300"
                value={props.widget.monthlyCarPayments}
                onChange={(event) => props.onChange("monthlyCarPayments", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Monthly Credit Card Payments"
                type="number"
                placeholder="e.g. 300"
                value={props.widget.monthlyCreditCardPayments}
                onChange={(event) => props.onChange("monthlyCreditCardPayments", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Other Monthly Obligations"
                type="number"
                placeholder="e.g. 100"
                value={props.widget.otherMonthlyObligations}
                onChange={(event) => props.onChange("otherMonthlyObligations", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="Input" fullWidth>
            <TextField
                label="Memo"
                multiline
                maxRows={3}
                fullWidth
                value={props.widget.memo}
                onChange={(event) => props.onChange("memo", event.target.value)}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Closing Costs Fixed / FLoor"
                type="number"
                placeholder="e.g. 3000"
                value={props.widget.closingCostsFixed}
                onChange={(event) => props.onChange("closingCostsFixed", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Closing Costs Variable Percentage"
                type="number"
                placeholder="e.g. .00125"
                value={props.widget.closingCostsVariablePercentage}
                onChange={(event) => props.onChange("closingCostsVariablePercentage", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Closing Costs Variable Threshold"
                type="number"
                placeholder="e.g. 150000"
                value={props.widget.closingCostsVariableThreshold}
                onChange={(event) => props.onChange("closingCostsVariableThreshold", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input">
            <TextField
              label="Apply Now Link Url"
              multiline
              maxRows={3}
              fullWidth
              value={props.widget.applyNowLinkUrl}
              onChange={(event) => props.onChange("applyNowLinkUrl", event.target.value)}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Estimated Taxes And Insurance % of P&I (0.00)"
                type="number"
                placeholder="e.g. .2"
                value={props.widget.estimatedTaxesAndInsuranceSharePerc}
                onChange={(event) => props.onChange("estimatedTaxesAndInsuranceSharePerc", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Conservative Front End Ratio (0.00)"
                type="number"
                placeholder="e.g. .28"
                value={props.widget.conservativeFrontEndRatio}
                onChange={(event) => props.onChange("conservativeFrontEndRatio", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Aggressive Front End Ratio (0.00)"
                type="number"
                placeholder="e.g. .36"
                value={props.widget.aggressiveFrontEndRatio}
                onChange={(event) => props.onChange("aggressiveFrontEndRatio", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Conservative Back End Ratio (0.00)"
                type="number"
                placeholder="e.g. .36"
                value={props.widget.conservativeBackEndRatio}
                onChange={(event) => props.onChange("conservativeBackEndRatio", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
          <Box className="Input" fullWidth>
            <TextField
                fullWidth
                label="Aggressive Back End Ratio (0.00)"
                type="number"
                placeholder="e.g. .45"
                value={props.widget.aggressiveBackEndRatio}
                onChange={(event) => props.onChange("aggressiveBackEndRatio", event.target.value, 'number')}
                variant="outlined"
              />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
