import * as React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import InfoIcon from '@material-ui/icons/Info'
import RemoveIcon from '@material-ui/icons/Remove'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { TextareaAutosize } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Api from './Api';


export default class WidgetEditor_PaymentCalculator_Products extends React.Component {

  state = {
    availableProducts: [],
    products: [],
    excludedProducts: [],
    productInEdit: null,
    isProductNew: false
  };

  constructor(props) {
    super(props);
    this.state.products = props.products;
    this.persistChange = props.changeHandler;
    this.state.config = props.config;
  }

 
  moveUp(product) {
    var products = this.clone(this.state.products);
    var i = products.findIndex(p => { return p.code == product.code; });
    if (i < 1)
      return;
    var stage = products[i - 1];
    products[i - 1] = products[i];
    products[i] = stage;
    this.setState({ products: products });
    this.persistChange('products', products);
  }

  moveDown(product) {
    var products = this.clone(this.state.products);
    var i = products.findIndex(p => { return p.code == product.code; });
    if (i >= products.length - 1)
      return;
    var stage = products[i + 1];
    products[i + 1] = products[i];
    products[i] = stage;
    this.setState({ products: products });
    this.persistChange('products', products);
  }

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  


  addProduct = () => {

    var api = new Api();
    api.getProducts(this.state.config.siteId, this.state.config.apiVersion, results => {
      this.state.availableProducts = results;

      var productInEdit = {
        code: '',
        display: '',
        loanTerm: 360,
        productType: 'conventional'
      };
  
      this.setState({isProductNew: true });
      this.setState({productInEdit});
    });
    
  }

  editProduct(product) {

    var api = new Api();
    api.getProducts(this.state.config.siteId, this.state.config.apiVersion, results => {
      this.state.availableProducts = results;

      this.setState({isProductNew: false });
      this.setState({productInEdit: product });
    });
  }

  onProductChange = (field, value) => {
    var productInEdit = this.clone(this.state.productInEdit);
    if (field == 'loanTerm') value = Number(value);
    productInEdit[field] = value;
    this.setState({ productInEdit });
  }

  completeProductEdit = () => {
    var products = this.clone(this.state.products);
    var i = products.findIndex(p => { return p.code == this.state.productInEdit.code; });
    
    if (i == -1) {
      products.push(this.state.productInEdit);
    } else {
      products.splice(i, 1, this.state.productInEdit);
    }
    this.setState({products});
    this.setState({productInEdit: null });
    this.persistChange('products', products);
  }

  removeProduct(product) {
    if (!window.confirm("Are you sure you want to remove this product?")) {
      return;
    }
    var products = this.clone(this.state.products);
    var i = products.findIndex(p => { return p.code == product.code; });
    products.splice(i,1);
    this.setState({products});
    this.setState({productInEdit: null });
    this.persistChange('products', products);
  }


  render() {
    var products = this.state.products;

    return (
      <React.Fragment>
        { !this.state.productInEdit &&
          <Box>
            <TableContainer component={Paper} style={{marginBottom:"30px"}}>
              <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{background:"rgb(235 235 235)"}}>
                    <TableCell>Product Code</TableCell>
                    <TableCell align="left">Display Name</TableCell>
                    <TableCell align="right">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product, index) => (
                    <TableRow
                      key={product.code}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="product">
                        {product.code}
                      </TableCell>
                      <TableCell align="left">{product.display}</TableCell>
                      <TableCell align="right">
                      { index > 0 &&
                          <Button
                            variant="contained"
                            startIcon={<ArrowUpward />}
                            class="mbtn mbtn-arrow-up"
                            onClick={() => this.moveUp(product)}
                          ></Button>
                        }
                        { index < products.length - 1 &&
                          <Button
                            variant="contained"
                            startIcon={<ArrowDownward />}
                            class="mbtn mbtn-arrow-down"
                            onClick={() => this.moveDown(product)}
                          >
                          </Button>
                        }
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          class="mbtn mbtn-edit"
                          onClick={() => this.editProduct(product)}
                        >
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                   <TableRow>
                    <TableCell colSpan={3}>
                      <Link onClick={() => this.addProduct()}>
                        <AddIcon></AddIcon> <div>Add Product</div>
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        }
        {this.state.productInEdit && 
          <React.Fragment>
            <div className="curtain"></div>
            <Paper className="paper product-edit" variant="elevation">
              <Grid container spacing={2}>
                <Grid item xs={6} align="left">
                  <Box className="Input" sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                      <InputLabel className="selectLabelCorrection">Product (Select Fixed Rate Product Only!)</InputLabel>
                      <Select
                        value={this.state.productInEdit.code}
                        label="Product"
                        disabled={!this.state.isProductNew}
                        onChange={(event) => this.onProductChange("code", event.target.value)}
                        variant="outlined"
                      >
                        { this.state.availableProducts.map(p => (
                          <MenuItem value={p}>{p}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6} align="left">
                  <Box className="Input" sx={{ minWidth: 120 }}>
                    <TextField
                        fullWidth
                        label="Display"
                        value={this.state.productInEdit.display}
                        onChange={(event) => this.onProductChange("display", event.target.value)}
                        variant="outlined"
                    />
                  </Box>
                </Grid>
               
                <Grid item xs={4} align="left">
                  <FormControl fullWidth>
                      <InputLabel className="selectLabelCorrection">Product Type</InputLabel>
                      <Select
                        value={this.state.productInEdit.productType}
                        label="Product Type"
                        onChange={(event) => this.onProductChange("productType", event.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="conventional">Conventional</MenuItem>
                        <MenuItem value="fha">FHA</MenuItem>
                        <MenuItem value="va">VA</MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} align="left">
                  <Box className="Input" sx={{ minWidth: 120 }}>
                    <TextField
                        label="Loan Term In Months"
                        fullWidth
                        type="number"
                        value={this.state.productInEdit.loanTerm}
                        onChange={(event) => this.onProductChange("loanTerm", event.target.value)}
                        variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} align="left">
                  { !this.state.isProductNew && 
                    <Button onClick={() => this.removeProduct(this.state.productInEdit)} variant="contained" color="secondary">REMOVE</Button>
                  }
                </Grid>
                <Grid item xs={6} align="right">
                  <Button onClick={() => { this.setState({ productInEdit: null})}}>Cancel</Button>
                  { this.state.isProductNew && 
                    <Button onClick={() => this.completeProductEdit()} variant="contained" color="primary">ADD PRODUCT</Button>
                  }
                  { !this.state.isProductNew && 
                    <Button onClick={() => this.completeProductEdit()} variant="contained" color="primary">SAVE PRODUCT</Button>
                  }
                  
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        }

        </React.Fragment>
    )
  };
}